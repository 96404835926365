import React from "react"
import { Homepage as PageQuery, Link, Meta, HomePageSEO, Menu, SiteSettings, SiteBanner } from 'src/queries'
import { graphql } from "gatsby"
import Layout from 'src/components/layout'
import Slices from 'src/components/slices'
import Instagram from 'src/components/instagram'
import { getPageData } from 'src/utilities/data'
import SEO from 'src/components/seo'

const Home = getPageData(({ pageData, menuData, siteBannerData, siteSettings, pageContext }) => {
  return (
    <Layout pageContext={pageContext} menuData={menuData} siteBannerData={siteBannerData} siteSettings={siteSettings} home>
      <style>{`
        body {
          --nav-bar-transparent-color: ${pageData.top_bar_content_color || 'white'};
          --nav-bar-transparent-contrast-color: ${pageData.top_bar_contrast_color || 'black'};
        }
      `}
      </style>
      <SEO {...pageData} lang={pageContext.lang} />
      <Slices data={pageData.body} merchantLogos={pageData.merchant_logos} appBadges={menuData.app_badges} />
      <Instagram menuData={menuData} />
    </Layout>
  )
})

Home.fragments = [PageQuery, Link, Meta, HomePageSEO, Menu, SiteSettings, SiteBanner]
export default Home
export const query = graphql`
  query ($lang: String) {
    prismic {
      allHomepage_15s(lang: $lang) { ...Homepage }
      allMenus(lang: $lang) { ...Menu }
      allSite_settingss(lang: $lang) { ...SiteSettings }
      allSite_banners(lang: $lang) { ...SiteBanner }
    }
  }
`